import React from 'react';
import styles from './FilterInputComponent.module.css';

function FilterInputComponent(props) {
  const {
    value,
    placeholder,
    onChange,
  } = props;

  return (
    <div className={styles.searchDiv}>
      {/* <p>{text?.searchtitle}</p> */}
      <img
        className={styles.searchIcon}
        src={require('../../assets/svgs/search.svg')}
        alt="search"
      />
      <input
        type="text"
        value={value}
        placeholder={placeholder}
        onChange={e => onChange(e.target.value)}
      />
      <div
        className={styles.closeButton}
        onClick={() => onChange('')}
      >
        ✕
      </div>
    </div>
  );
}

export default FilterInputComponent;
