import React from 'react';
import Collapsible from 'react-collapsible';
import './styles.css';

function AccordionComponent(props) {
  const {
    title,
    children,
  } = props;

  return (
    <Collapsible
      trigger={title}
      triggerClassName="CustomTriggerCSS"
      triggerOpenedClassName="CustomTriggerCSS--open"
      transitionTime={300}
    >
      {children}
    </Collapsible>
  );
}

export default AccordionComponent;
