import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import CalculatorComponent from "./Components/CalculatorComponent/CalculatorComponent";

import './App.css';

const accountIdKey = '@accountId';


function App() {
	const [items, setItems] = useState();
	const [customItems, setCustomItems] = useState();
	const [monthlyStorage, setMonthlyStorage] = useState();
	const [text, setText] = useState();
	const [content, setContent] = useState();
	const [accountId, setAccountId] = useState();

	useEffect(_ => {

		let _accountId = localStorage.getItem(accountIdKey);
		if (!_accountId) {
			_accountId = uuidv4();
			localStorage.setItem(accountIdKey, _accountId);
		}
		setAccountId(_accountId);

		(async () => {
			const contentful = require("contentful");
			const contentfulClient = contentful.createClient({
				space: "iim1pelwcgkd",
				accessToken: "bDpVzFxyzA6wjl81z2kqMq_1kSJR7wvLzH00bSbQk18",
			});

			await contentfulClient
				.getEntry("25kU0u7tonJ5QVnL8Nd9wu")
				.then((res) => {
						setContent(res.fields)
				})
				.catch((err) => console.log(err));

			await contentfulClient
				.getEntry("jsteOPJPTxbRWr1Ll9xiy")
				.then((res) => {
						res.fields.items.items = res.fields.items.items.map(item => {
								item.planItems = item.planItems.map((plan, i) => {
										plan.id = i;
										return plan;
								})
								item.image = res.fields.images.filter(img => img.fields.title == item.id)[0].fields.file.url;

								return item;
						})
						// dont change item category names on Contentful, it will crash the app
						setItems(res.fields)
				})
				.catch((err) => console.log(err));

			await contentfulClient
				.getEntry("5KWolgkg9JQP55PFZU6G9N")
				.then((res) => {
					setCustomItems(res.fields)
				})
				.catch((err) => console.log(err));
			await contentfulClient
				.getEntry("5PmxMyAZokqzr5KdqBc0tC")
				.then((res) => {
					setText(res.fields)
				})
				.catch(e => e)
			await contentfulClient
				.getEntry("3bqzw3KEfu48EA24nf9af1")
				.then((res) => {
					setMonthlyStorage(res.fields)
				})
				.catch(e => e)
		})()
	}, []);

	console.log('accountId ==>', accountId);

	return (
		<div className="App">
			<div className="header">
				<div className="header__container">
					{text?.logo && <img src={text?.logo?.fields.file.url} alt="logo" />}
				</div>
			</div>
			{items && monthlyStorage && (
				<CalculatorComponent
					accountId={accountId}
					content={content}
					items={items}
					customItems={customItems}
					monthlyStorage={monthlyStorage}
					text={text}
				/>
			)}
		</div>
	);
}

export default App;
