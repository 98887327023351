import React, { Component } from "react";

import API from "../../utils/ApiUtils";

import './style.css';


class BookingPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      airtableData: {},
      loading: false,
      submitting: false,
    };
  }

  componentDidMount() {
  }

  async componentDidUpdate (prevProps, prevState) {
    const { isAuthenticated, user } = this.props;
    const { isAuthenticated: oldAuthStats } = prevProps;

    if ( oldAuthStats === false && isAuthenticated === true ) {
      this.setState({ loading: true })
      let response = await API.getUserBookingList(user.email)
      // let response = await API.getUserBookingList('fadi@otaqui.com')
      this.setState({ airtableData: response, loading: false })
    }
  }

  _handleSubmit = async (event) => {
    event.preventDefault();

    const { stateOrders, isAuthenticated, user } = this.props;
    const { airtableData: { recordID } } = this.state;
    if ( isAuthenticated && user.email ) {
      this.setState({ loading: true })
      let response = await API.updateUserInventory(user, recordID, stateOrders)
      this.setState({ loading: false })
    }
  }

  render() {
    const { isAuthenticated, stateOrders } = this.props;
    const { airtableData, loading } = this.state;

    return (
      <div className={`panel ${isAuthenticated ? 'show' : 'hide'}`}>
        <div className="panel-heading">
          <h3>Bookings</h3>
        </div>
        <div className="panel-body">
          {
            airtableData && airtableData.bookings ?
            <div className="bookings">
              {
                airtableData.bookings.map((booking, index) => (
                  <div className="booking" key={`booking_${index}`}>
                    <span className="badge badge--active">{ booking.bookingID }</span>
                    <span>{ booking.unitSize }</span>
                  </div>
                ))
              }
            </div>
            :
            null
          }

          <button
            className="panel__submit"
            onClick={this._handleSubmit}
            disabled={(stateOrders || []).length === 0}
          >
            Apply
          </button>

          { loading ? <div className="loader-wrap"><div className="component-loader"></div></div> : null }
        </div>
      </div>
    );
  }
}

export default BookingPanel;
