import axios from 'axios'

import { API_BOOKING_LIST, API_UPDATE_INVENTORY } from './ApiConfig'

const ApiUtils = {
  getUserBookingList: async function(email) {
    let payload = {
      email
    };

    return axios.post(API_BOOKING_LIST, payload)
      .then(response => {
        return response.data;
      })
      .catch(err => {
        console.log(err)
      })
  },
  updateUserInventory: async function(user, prospectRecordID, inventories) {
    let payload = {
      user,
      inventories,
      prospectRecordID
    };

    return axios.post(API_UPDATE_INVENTORY, payload)
      .then(response => {
        return response.data;
      })
      .catch(err => {
        console.log(err)
      })
  },
}

export default ApiUtils
