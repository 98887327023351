import React, { useState, useEffect } from 'react';
import Loader from '../Loader/Loader';
import s from './LoadingModalComponent.module.css';

function LoadingModalComponent(props) {
  const {
    open,
    title,
    description,
    icon,
    backgroundColor,
    backdropColor,
  } = props;
  const [active, setActive] = useState();

  useEffect(() => {
    if (open) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'auto';
    }
    setActive(open);
  }, [open]);

  if (!active) {
    return null;
  }

  return (
    <div
      className={s.modal}
      style={{ backgroundColor: backdropColor || 'rgba(0, 0, 0, 0.3)' }}
      onScroll={event => event.stopPropagation()}
    >
      <div
        className={s.paper}
        style={{ backgroundColor: backgroundColor || 'white' }}
      >
        {icon && (
          <div className={s.logo}>
            <img src={icon} alt="Logo icon" />
          </div>
        )}
        {title && (
          <div className={s.title} align="center">
            {title}
          </div>
        )}
        {description && (
          <div className={s.description} align="center">
            {description}
          </div>
        )}
        <Loader />
      </div>
    </div>
   
  );
}

export default LoadingModalComponent;
