import React, { useState } from 'react';
import Input from '../Input/InputComponent';
import Counter from '../Counter/CounterComponent';
import Button from '../Button/ButtonComponent';
import s from './AddItemComponent.module.css';

function AddItemComponent(props) {
  const {
    onCancel,
    onAdd,
    onAdding,
  } = props;

  const [name, setName] = useState('');
  const [width, setWidth] = useState(0);
  const [length, setLength] = useState(0);
  const [depth, setDepth] = useState(0);
  const [quantity, setQuantity] = useState(1);

  function handleAdd() {
    if (!!name && width > 0 && length > 0 && depth > 0 && quantity > 0) {
      const value = {
        name,
        width,
        length,
        depth,
        quantity,
      }
      onAdd(value);
    }
  }

  console.log('name ==>', name)
  console.log('width ==>', width)
  console.log('length ==>', length)
  console.log('depth ==>', depth)
  console.log('quantity ==>', quantity)

  const valid = !!name && width > 0 && length > 0 && depth > 0 && quantity > 0;

  console.log('valid ==>', !valid)

  return (
    <div className={s.addItem}>
      <div className={s.innerContent}>
        <h5>Add a new item</h5>
        <Input
          type="text"
          label=""
          placeholder="Name"
          value={name}
          maxLength={25}
          onChange={setName}
        />
        <Input
          label="Width (cm):"
          placeholder=""
          value={width}
          min={0}
          onChange={setWidth}
          
        />
        <Input
          label="Length (cm):"
          placeholder=""
          value={length}
          min={0}
          onChange={setLength}
        />
        <Input
          label="Depth"
          placeholder=""
          value={depth}
          min={0}
          onChange={setDepth}
        />
      </div>

      <Counter
        title="Quantity"
        value={quantity}
        noSize
        onChange={setQuantity}
      />

      <div className={s.actionContent}>
        <Button
          title="Cancel"
          size="md"
          variant="outline"
          onClick={onCancel}
        />
        <Button
          title="Add"
          size="md"
          onClick={handleAdd}
          disabled={!valid || onAdding}
        />
      </div>
    </div>
  );
}

export default AddItemComponent;
