import React, { useState, useEffect } from "react";
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';

import CalculationResultComponent from "../CalculationResult/CalculationResultComponent";
import CounterComponent from '../Counter/CounterComponent';
import AccordionComponent from '../Accordion/AccordionComponent';
import FilterResultComponent from '../FilterResult/FilterResultComponent';
import FilterInput from '../FilterInput/FilterInputComponent';
import AddItemComponent from '../AddItem/AddItemComponent';
import Button from '../Button/ButtonComponent';
import LoadingModalComponent from '../LoadingModal/LoadingModalComponent';
import s from './CalculatorComponent.module.css';

import BookingPanel from "../BookingPanel";
import { useAuth0 } from "@auth0/auth0-react";

const contentfulManagement = require('contentful-management');
const client = contentfulManagement.createClient({
  accessToken: 'CFPAT-KnpJwGdB__pmrD6P218z3hOsH7WqtmU6uuYLfVrTYjo',
})

const supportEmail = 'support@spacehero.gr';
const zapWebhookURL = "https://hooks.zapier.com/hooks/catch/3088667/b15tjcf/";

const encodeForm = (data) => {
  var formData = new FormData();

  for ( var key in data ) {
    if ( typeof data[key] === 'object' ) {
      formData.append(key, JSON.stringify(data[key]));
    } else {
      formData.append(key, data[key]);
    }
  }

  return formData;
}

const accountIdKey = '@accountId';


function CalculatorComponent(props) {
  const {
    content,
    text,
    items,
    customItems,
    monthlyStorage,
    accountId,
  } = props;

  const [data, setData] = useState([]);
  const [stateOrders, setstateOrders] = useState([]);
  const [cubicFotage, setcubicFotage] = useState(0);
  const [cost, setCost] = useState(0)
  const [suggestedSize, setSuggestedSize] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [filterData, setFilterData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showSubmitModal, setShowSubmitModal] = useState(false);

  const [newItems, setNewItems] = useState([]);
  const [newCustomItems, setNewCustomItems] = useState([]);
  const [submissionMessage, setSubmissionMessage] = useState({});

  const [notification, setNotification] = useState({});
  const [addingItem, setAddingItem] = useState(false);

  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    logout,
  } = useAuth0();

  useEffect(() => {
    console.log('first load items ==>', items);
    console.log('first load customItems ==>', customItems);

    // const _data = items.items.items.map(item => ({
    //   ...item,
    //   adding: false,
    // }))

    const _data = [];
    let _newItems = newItems;
    let _newCustomItems = newCustomItems;

    for (let i = 0; i < items.items.items.length; i++) {
      const item = items.items.items[i];
      item.adding = false;

      if (customItems && customItems.items[accountId] && customItems.items[accountId][item.id]) {
        let planCustomItems = customItems.items[accountId][item.id];
        let planItems = item.planItems;

        planCustomItems.forEach(pci => {
          if ( pci.id ) {
            let planItem = planItems.find(pi => { return pi.id == pci.id });

            if ( planItem ) {
              planItem.value = pci.value;
              _newItems = createOrUpdateItems(_newItems, pci);
            }
          } else {
            _newCustomItems = createOrUpdateItems(_newCustomItems, pci);
          }
        })
      }

      _data.push(item);
    }

    setData(_data);
    calcuate(_data);

    setNewItems(_newItems);
    setNewCustomItems(_newCustomItems);

  }, [items, customItems, accountId]);

  function getSelectedCount(item) {
    let result = 0;
    for (let i = 0; i < item.planItems.length; i++) {
      const planItem = item.planItems[i];
      if (!!planItem.value) {
        result = result + planItem.value;
      }
    }

    return result;
  }

  function handleCounterChanged(value, index, planIndex) {
    const _data = [...data];
    _data[index].planItems[planIndex].value = value;
    calcuate(_data);
    setData(_data);

    updateContentful(index, _data[index].planItems[planIndex]);
  }

  function calcuate(_data) {
    let cost = 0;
    let totalSize = 0;
    // EGOR: size attribute divided by 10 = m3 volume value

    const selectedPlans = {};

    _data.forEach((plan) => {
      plan.planItems.forEach((item) => {
        if (item.value > 0) {
          if (!selectedPlans[plan.id]) {
            selectedPlans[plan.id] = {
              ...plan,
              planItems: []
            }
          }

          selectedPlans[plan.id].planItems.push(item);
          cost += (item.size * item.value) / 10;
          totalSize += item.size * item.value;
        }
      })
    });

    console.log('selectedPlans ==>', selectedPlans)

    setstateOrders(Object.values(selectedPlans));
    setcubicFotage(totalSize.toFixed(2));
    let max = []
    content && content.prices && content.prices.plans.forEach((plan, i) => {
      if (plan.volume >= Math.ceil(totalSize))
        max.push(plan)
    })
    max = max.sort((a, b) => {
      return a.price > b.price
    })

    if (max.length > 0) {
      setSuggestedSize(max[0].size);
      // max[0].volume;
      // setCost((max[0].price) || props.content && props.content.prices.plans[props.content.prices.plans.length - 2].price)

    } else {
      const area = Math.ceil(totalSize / 2);
      const sqrtArea = Math.floor(Math.sqrt(area));
      let width = 1;
      let length = sqrtArea;
      for (let i = sqrtArea; i > 0; i--) {
          if ((area % i) === 0) {
              width = i;
              length = area / i;
              break;
          }
      }
      setSuggestedSize(`${width}m x ${length}m`);

      let maxPlan = { volume: 4, price: 40 };
      if (props?.content?.prices) {
        for (let i = 0; i < props.content.prices.plans.length; i++) {
          const plan = props.content.prices.plans[i];
          if (plan.volume >= maxPlan.volume) {
            maxPlan = plan;
          }
        }
      }

      // Math.ceil(totalSize)
      // setCost((Math.ceil(totalSize) - maxPlan.volume) * 5 + maxPlan.price);
    }

    let m8Service = monthlyStorage?.services?.services?.filter(service => service.type === 'm8')
    m8Service = m8Service ? m8Service[0] : {}

    const { base, volumePlus, additional, additionalPlus } = m8Service;

    let price = base;
    const totalVolume = Math.round(totalSize);
    if (totalVolume > 4 && totalVolume <= volumePlus) {
      price += additional * (totalVolume - 4);
    } else if (totalVolume > volumePlus) {
      price += additional * (volumePlus - 4);
      price += additionalPlus * (totalVolume - volumePlus);
    }
    setCost(price);

  }

  function normalizeGreek(text) {
    return text.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
  }

  function handleInputChange(value) {
    setSearchValue(value);
    let _filterData = [];
    if (!!value) {
      for (let i = 0; i < data.length; i++) {
        const planItems = data[i].planItems;
        for (let j = 0; j < planItems.length; j++) {
          if (normalizeGreek(planItems[j].name.toLowerCase()).includes(value.toLowerCase()) ||
              normalizeGreek(planItems[j].name.toLowerCase()).includes(normalizeGreek(value.toLowerCase()))
          ) {
            _filterData.push(planItems[j]);
          }
        }
      }
    }
    setFilterData(_filterData);
  };

  function handleFilterItemChange(newValue, planItem) {
    let _data = [...data];
    for (let i = 0; i < _data.length; i++) {
      let planItems = _data[i].planItems;
      for (let j = 0; j < planItems.length; j++) {
        if (planItems[j].id === planItem.id && planItems[j].name === planItem.name) {
          _data[i].planItems[j].value = newValue;
        }
      }
    }
    calcuate(_data);
    setData(_data);
  }

  function handleResultSend() {
    let mail = document.createElement('a');
    let data = `{
      "list": ${JSON.stringify(stateOrders)},
      "volumn": "${cubicFotage}m³",
      "size": "${suggestedSize}",
      "cost": "${typeof cost === 'string' ? cost : `€${cost.toFixed(2)}/mo`}"
    }`;
    mail.href = `mailto:${supportEmail}?subject=Calculator&body=${data}`;
    mail.click();
  }

  function handleResultChange(value, id, planItemId, planItemName = null) {
    let _data = [...data];
    let selectedPlan = null;
    let selectedDataIndex = null;

    for (let i = 0; i < _data.length; i++) {
      if (_data[i].id === id) {
        let planItems = _data[i].planItems;
        selectedDataIndex = i;
        for (let j = 0; j < planItems.length; j++) {
          if (planItemId && planItems[j].id == planItemId) {
            _data[i].planItems[j].value = value;
            selectedPlan = _data[i].planItems[j];
          } else if (planItemName && planItems[j].name == planItemName) {
            _data[i].planItems[j].value = value;
            selectedPlan = _data[i].planItems[j];
          }
        }
      }
    }

    calcuate(_data);
    setData(_data);

    if ( selectedPlan ) {
      updateContentful(selectedDataIndex, selectedPlan);
    }
  }

  function handleAddingStateChange(index, value) {
    let _data = [...data];
    _data[index].adding = value;
    setData(_data);
  }

  function validateNewItem(id, itemName) {
    let _data = [...data];
    for (let i = 0; i < _data.length; i++) {
      if (_data[i].id == id) {
        let planItems = _data[i].planItems;
        for (let j = 0; j < planItems.length; j++) {
          if (planItems[j].name == itemName) {
            return false;
          }
        }
      }
    }

    return true;
  }

  function handleAddNewItem(index, value) {
    console.log('data ==>', data)
    console.log('index ==>', index);
    console.log('value ==>', value);

    const {
      depth,
      length,
      name,
      quantity,
      width,
    } = value;

    let isValidItem = validateNewItem((index + 1), name);

    if ( isValidItem ) {

      const newItem = {
        name: name,
        size: (width * length * depth / 1000000),
        value: quantity,
      };

      setAddingItem(true);

      client.getSpace('iim1pelwcgkd').then(space => {
        return space.getEnvironment('master');
      })
      .then(environment => {
        return environment.getEntry('5KWolgkg9JQP55PFZU6G9N');
      })
      .then(entry => {
        console.log('entry ==>', entry);
        const _myItemData = entry.fields.items.el[accountId] || {};
        const _myItemDataItem = _myItemData[data[index].id] || [];

        const myItemData = {
          ...entry.fields.items.el,
          [accountId]: {
            ..._myItemData,
            [data[index].id]: [
              ..._myItemDataItem,
              newItem
            ]
          }
        }

        entry.fields.items.el = myItemData;

        // Add new items to zap list
        let _newCustomItems = createOrUpdateItems(newCustomItems, newItem);
        setNewCustomItems(_newCustomItems);

        return entry.update();
      })
      .then(entry => {
        return entry.publish();
      })
      .then(entry => {
        console.log('entry xxx==>', entry);
        const _data = [...data];
        _data[index].planItems = [
          ..._data[index].planItems,
          newItem,
        ];
        calcuate(_data);
        setData(_data);
        handleAddingStateChange(index, false);
        setAddingItem(false);
      })
      .catch(e => {
        handleAddingStateChange(index, false);
        setAddingItem(false);
        console.log('e ==>', e);
      })

    } else {
      setNotification({
        show: true,
        content: 'The item name was already taken.',
        type: 'error',
      });

      setTimeout(() => {
        setNotification({ show: false, content: '', type: '' });
      }, 5000);
    }

  }

  function updateContentful(index, selectedPlan) {
    const {
      id,
      name,
      size,
      value,
    } = selectedPlan;
    const newItem = { id, name, size, value };

    // setLoading(true);

    client.getSpace('iim1pelwcgkd').then(space => {
      return space.getEnvironment('master');
    })
    .then(environment => {
      return environment.getEntry('5KWolgkg9JQP55PFZU6G9N');
    })
    .then(entry => {
      const _myItemData = entry.fields.items.el[accountId] || {};
      const _myItemDataItem = _myItemData[data[index].id] || [];

      const myItemData = {
        ...entry.fields.items.el,
        [accountId]: {
          ..._myItemData,
          [data[index].id]: [
            ..._myItemDataItem,
            newItem
          ]
        }
      }

      entry.fields.items.el = myItemData;

      // Prepare submit lst for new quantity
      let _newItems = createOrUpdateItems(newItems, newItem);
      setNewItems(_newItems);

      return entry.update();
    })
    .then(entry => {
      return entry.publish();
    })
    .then(entry => {
      setLoading(false);
    })
    .catch(e => {
      setLoading(false);
      console.log('e ==>', e);
    })
  }

  const handleSumbitList = (email) => {
    setLoading(true);

    let _newItems = newItems.map(item => {
      return {
        name: item.name,
        total: item.size * item.value,
        value: item.value,
      }
    });

    let _newCustomItems = newCustomItems.map(item => {
      return {
        name: item.name,
        total: item.size * item.value,
        value: item.value,
      }
    });

    let payload = {
      newItems: _newItems,
      newCustomItems: _newCustomItems,
      email,
      totalVolume: cubicFotage,
      estimatedPrice: cost,
      plan: text?.sidebar?.planLengthMin
    };

    axios.post(
      zapWebhookURL,
      encodeForm(payload),
      { headers: { 'Accept': 'application/json' } }
    ).then((response) => {
      console.log(response);
      setLoading(false);
      setSubmissionMessage({
        content: 'Submitted Successfully.',
        type: 'success'
      });

      setTimeout(() => {
        setShowSubmitModal(false);

        // refresh session
        let _accountId = uuidv4();
        localStorage.setItem(accountIdKey, _accountId);

        window.location.href = 'https://spacehero.gr/success';
      }, 2000);
    }).catch(e => {
      console.log('e ==>', e);
      setSubmissionMessage({
        content: 'Something went to wrong. Please try again.',
        type: 'error'
      });
    })
  }

  const createOrUpdateItems = (targetItems, item) => {
    const { name, value, size } = item;

    let _itemIndex;
    let _item = targetItems.find((ni, i) => {
      if ( ni.name == item.name ) {
        _itemIndex = i;
        return true;
      }

      return false;
    });

    if ( value == 0 ) {
      targetItems.splice(_itemIndex, 1);
    } else {
      if ( _item ) {
        _item.value = item.value;
      } else {
        targetItems.push({ name, value, size });
      }
    }

    return targetItems;
  }

  return (
    <div>
      <div className={s.resultItems}>
        <div className={s.mainContainer}>
          <div className={s.planComponent}>
            <div className={s.planTitle}>
              <h2>{text?.title}</h2>
              <h6>{text?.subtitle}</h6>
            </div>

          </div>

          <div className="row">
            <div className="col-two-thirds">

              <div className={s.listContainer}>
                <FilterInput
                  value={searchValue}
                  placeholder={text?.searchplaceholder}
                  onChange={handleInputChange}
                />
                {
                  searchValue
                    ? (
                      <FilterResultComponent
                        searchTitle={`${text?.searchtitle} "${searchValue}"...`}
                        value={searchValue}
                        data={filterData}
                        onChange={handleFilterItemChange}
                      />
                    )
                    : data.length > 0 && data.map((item, index) => (
                      <AccordionComponent
                        key={`item-${index}`}
                        title={`${item.name} (${getSelectedCount(item)})`}
                      >
                        {item.planItems.map((planItem, planIndex) => (
                          <CounterComponent
                            key={`item-${index}-planItem-${planIndex}`}
                            title={planItem.name}
                            value={planItem.value || 0}
                            size={planItem.size}
                            onChange={newValue => handleCounterChanged(newValue, index, planIndex)}
                          />
                        ))}

                        <div className={s.addItemContainer}>
                          {item.adding ? (
                            <AddItemComponent
                              onCancel={() => handleAddingStateChange(index, false)}
                              onAdd={(data) => handleAddNewItem(index, data)}
                              onAdding={addingItem}
                            />
                          ): (
                            <Button
                              title="Add a new item"
                              size="md"
                              variant="outline"
                              onClick={() => handleAddingStateChange(index, true)}
                            />
                          )}
                        </div>
                      </AccordionComponent>
                  ))
                }
              </div>
            </div>

            <div className="col-one-thirds">
              <CalculationResultComponent
                text={text}
                stateOrders={stateOrders}
                cubicFotage={cubicFotage}
                cost={cost}
                suggestedSize={suggestedSize}
                onSend={handleResultSend}
                onChange={handleResultChange}
                loginWithRedirect={loginWithRedirect}
                onClickSubmit={() => {
                  setShowSubmitModal(true);
                  setSubmissionMessage({});
                }}
              />

              <BookingPanel
                user={user}
                isAuthenticated={isAuthenticated}
                stateOrders={stateOrders}
              />
            </div>
          </div>
        </div>
      </div>

      <LoadingModalComponent
        // title={context?.content?.misc?.loaderTitle}
        // description={context?.content?.misc?.loaderDescription}
        // icon={context?.content?.misc?.logo?.fields?.file.url}
        backgroundColor="transparent"
        open={loading}
      />

      {
        showSubmitModal ?
        <SubmissionModalComponent
          handleClose={() => setShowSubmitModal(false)}
          handleSumbitList={handleSumbitList}
          message={submissionMessage?.content}
          messageType={submissionMessage?.type}
        />
        :
        null
      }

      {
        notification.show ?
        <NotificationComponent
          handleClose={() => setNotification({ show: false, content: '', type: '' })}
          message={notification?.content}
          messageType={notification?.type}
        />
        :
        null
      }

    </div>
  );
}

export default CalculatorComponent;

//////////////////////////////////////
////// SubmissionModalComponent //////
//////////////////////////////////////

function SubmissionModalComponent(props) {
  const {
    handleClose,
    handleSumbitList,
    message,
    messageType,
  } = props;

  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const handleEmailChange = (event) => {
    const { value } = event.target;
    setEmail(value);
  }

  const handleSubmit = () => {
    if (email) {
      handleSumbitList(email);
    } else {
      setError('Please enter your email.');
    }
  }

  return (
    <div className={s.submissionModal}>
      {
        message &&
        <div className={s[`submissionModalMessage__${messageType}`]}>{message}</div>
      }

      <h3 className={s.submissionModalTitle}>Αποστολή λίστας αντικειμένων</h3>
      <h5 className={s.submissionModalSubtitle}>Σε ποιο email θα μπορούσαμε να σας την στείλουμε</h5>

      <div className={s.submissionFormGroup}>
        <label className={s.submissionFormLabel}>Email</label>
        <input name="email" className={s.submissionFormControl} type="email" onChange={handleEmailChange} />
        <div className={s.submissionFormError}>{error}</div>
      </div>

      <button
        className={s.submissionButton}
        onClick={() => handleSubmit()}
      >
        Αποστολή
      </button>

      <div className={s.submissionModalClose} onClick={handleClose}></div>
    </div>
  );
}

//////////////////////////////////////
////// NotificationComponent //////
//////////////////////////////////////

function NotificationComponent(props) {
  const {
    handleClose,
    message,
    messageType,
  } = props;

  return (
    <div className={`${s.notification} ${s['notification__' + messageType]}`}>
      <div className={s.notificationContent}>{message}</div>
      <div className={s.notificationClose} onClick={handleClose}></div>
    </div>
  );
}
