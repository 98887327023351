import React from 'react';
import s from './InputComponent.module.css';

function InputComponent(props) {
  const {
    label,
    type = 'number',
    placeholder,
    onChange,
    ...rest
  } = props;

  return (
    <div className={s.inputComponent}>
      {label && <h6>{label}</h6>}
      <input
        type={type}
        placeholder={placeholder}
        onChange={e => onChange(type === 'number' ? parseInt(e.target.value) : e.target.value)}
        {...rest}
      />
    </div>
  );
}

export default InputComponent;
