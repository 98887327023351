import React from 'react';
import CounterComponent from '../Counter/CounterComponent';
import styles from './FilterResultComponent.module.css';

function FilterResultComponent(props) {
  const {
    searchTitle,
    data,
    onChange,
  } = props;

  return (
    <div className={styles.filterResult}>
      <h6>{searchTitle}</h6>
      <div className={styles.filterResultInner}>
        {data.map((planItem, planIndex) => (
          <CounterComponent
            key={`Filter-planItem-${planIndex}`}
            title={planItem.name}
            value={planItem.value || 0}
            size={planItem.size}
            onChange={newValue => onChange(newValue, planItem)}
          />
        ))}
      </div>
    </div>
  );
}

export default FilterResultComponent;
