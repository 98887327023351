import React from 'react';
import CounterComponent from '../Counter/CounterComponent';
import s from './CalculationResultComponent.module.css';
import check from './../../assets/svgs/check.svg'


const CalculationResultComponent = (props) => {
  const {
    text,
    stateOrders,
    cubicFotage,
    cost,
    suggestedSize,
    onChange,
    onSend,
    loginWithRedirect,
    onClickSubmit,
  } = props;

  return (
    <div className={s.resultContainer}>
      <div
        className={s.resultSection}
        style={{ paddingBottom: stateOrders?.length > 0 ? 0 : 33 }}
      >
        <h3>{text?.sidebar?.calculateYourCubicFootage}</h3>
        <div>
          {
            stateOrders.map((stateOrder, i) => (
              <div
                key={`CalculationResultComponent-stateOrder-${i}`}
                className={s.planItems}
              >
                <h4 className={s.resultTitle}>{stateOrder.name}</h4>
                {stateOrder.planItems.map((planItem, j) => (
                  <CounterComponent
                    key={`CalculationResultComponent-planItem-${j}`}
                    title={planItem.name}
                    value={planItem.value || 0}
                    size={planItem.size}
                    onChange={newValue => onChange(newValue, stateOrder.id, planItem.id, planItem.name)}
                  />
                ))}
              </div>
            ))
          }
        </div>
      </div>
      <div className={s.resultSectionLast}>
        <div>
          <h4 className={s.resultTitle}>{text?.sidebar?.yourCustomStoragePlan}</h4>
          <div className={s.resultCubicFotage}>
            <h6>{text?.sidebar?.totalCubicFootage}</h6>
            <h6>{cubicFotage}m<sup>3</sup></h6>
          </div>
          <div className={s.resultPlan}>
            {/* TODO: To be adjusted later */}
            {/* <h5>{suggestedSize}</h5> */}
            <h4>{typeof cost === 'string' ? cost : `€${cost.toFixed(2)}/mo`}</h4>
          </div>
          <h6 className={s.questionInform}>{text?.sidebar?.planLengthMin}</h6>
        </div>

        <button
          className={`${s.button}`}
          onClick={_ => window.location = 'https://spacehero.typeform.com/to/PWp4Iy'}
        >
          {text?.sidebar?.bookToday}
        </button>

        {/* <button
          className={`${s.button} ${s.outlineButton}`}
          onClick={() => loginWithRedirect()}
        >
          {text?.sidebar?.sendList}
        </button> */}

        <button
          className={`${s.button} ${s.outlineButton}`}
          onClick={() => onClickSubmit()}
        >
          {text?.sidebar?.sendList}
        </button>

        <div>
          <ul className={s.list}>
            {text?.sidebar?.features.map((feature, i) => (
              <li key={`CalculationResultComponent-feature-${i}`}>
                <img src={check} alt={"check-icon"} />
                {feature}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default CalculationResultComponent;
