import React from 'react';
import s from './ButtonComponent.module.css';

function ButtonComponent(props) {
  const {
    title,
    size = 'lg',
    variant = 'default',
    className,
    disabled,
    onClick,
  } = props;

  const sizeStyle = size === 'md' ? s.mdSizeButton : '';
  const variantStyle = variant === 'outline' ? s.outlineButton : '';

  return (
    <button
      className={`
        ${s.button}
        ${sizeStyle}
        ${variantStyle}
        ${className}
      `}
      disabled={disabled}
      onClick={onClick}
    >
      {title}
    </button>
  );
}

export default ButtonComponent;
