import React from 'react';
import styles from './CounterComponent.module.css';

const plusIcon = require('../../assets/svgs/plus.svg');
const minusIcon = require('../../assets/svgs/minus.svg');
const minusDisableIcon = require('../../assets/svgs/minus-disable.svg');

function CounterComponent(props) {
  const {
    title,
    value,
    size,
    noSize,
    onChange,
  } = props;

  function handleButtonClick(more) {
    const newValue = value + more;
    if (newValue < 0) {
      return;
    }
    onChange(newValue);
  }

  return (
    <div className={styles.counter}>
      <div className={styles.counterLeft}>
        <h5>{title}</h5>
        {!noSize && <h6>{(size * value).toFixed(2)}m<sup>3</sup></h6>}
      </div>
      <div className={styles.counterRight}>
        <button
          className={`${styles.counterButton} ${value === 0 && styles.counterButtonDisabled}`}
          onClick={() => handleButtonClick(-1)}
        >
          {value === 0
            ? <img src={minusDisableIcon} alt="minus" />
            : <img src={minusIcon} alt="minus" />
          }
        </button>
        <h6 className={styles.counterValue}>{value}</h6>
        <button
          className={`${styles.counterButton} `}
          onClick={() => handleButtonClick(1)}
        >
          <img src={plusIcon} alt="plus" />
        </button>
      </div>
    </div>
  );
}

export default CounterComponent;
